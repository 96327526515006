
import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from '../api/apiService'
import { ToastSuccess, ToastError } from "../components/toast-notification";

function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ password: '', confirmPassword: '' });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!formValues.password) {
      errors.password = 'Email is required';
    } else if (formValues.password.length < 5) {
      errors.password = 'Password length minimum 5';
    }
    if (!formValues.confirmPassword) {
      errors.confirmPassword = 'Email is required';
    } else if (formValues.confirmPassword.length < 5) {
      errors.confirmPassword = 'Confirm Password length minimum 5';
    } else if (formValues.confirmPassword !== formValues.password) {
      errors.confirmPassword = 'Password Confirm and Password not same';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const tryResetPass = (data) => {
    resetPassword(data)
      .then(function (response) {
        console.log(response);
        ToastSuccess(response?.data?.message);
        navigate(`/login`);
      })
      .catch(function (error) {
        console.log(error);
        ToastError(error?.response?.data?.message);

      });
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    validate(formValues)

    if (validate(formValues)) {
      tryResetPass({ token: params.token, newPassword: formValues.confirmPassword })
    }
  };

  return (
    <div className="page-content">

      <div className="twm-home4-banner-section site-bg-light-purple">
        <div className="row">
          <div className="col-xl-5 col-lg-12 col-md-12">
            <div className="twm-log-reg-form-wrap">
              <div className="twm-log-reg-logo-head">
                <div className="twm-log-reg-head">
                  <span className="log-reg-form-title">Reset Password</span>
                </div>
              </div>

              <div className="twm-log-reg-inner">
                <div className="twm-tabs-style-2">

                  <form onSubmit={handleSubmit}>
                    <div className="tab-content" id="myTab2Content">
                      <div className="tab-pane fade show active" id="twm-login-candidate">
                        <div className="row">

                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              {formErrors.password && <p className="color-error">{formErrors.password}</p>}
                              <input onChange={handleChange} name="password" type="password" className="form-control" required="" placeholder="Password*" />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              {formErrors.confirmPassword && <p className="color-error">{formErrors.confirmPassword}</p>}
                              <input onChange={handleChange} name="confirmPassword" type="password" className="form-control" required="" placeholder="Confirm Password*" />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="twm-forgot-wrap">
                              <div className="form-group mb-3">
                                <div className="form-check">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <button type="submit" className="site-button">Submit</button>
                            </div>

                          </div>
                          <div className="col-lg-12">
                            <div className="twm-forgot-wrap">
                              <div className="form-group">
                                <div className="form-check">
                                  <label className="form-check-label rem-forgot" for="Password4">Already have an account <a href="/login" className="site-text-primary">Login</a></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12">
            <img src="/assets/pngs/login-bg.png" alt="" />
          </div>
        </div>

      </div>

    </div>
  )
}

export default ResetPassword;
