import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { verifyEmailLink, verifyEmailForgotPassword } from '../api/apiService'
import React, { useState, useEffect } from 'react';

function VerifyEmail() {
  const navigate = useNavigate();
  const params = useParams();
  const [statusObj, setStatueObj] = useState({});
  const [isVerifying, setVerifying] = useState(true);
  const [redirectTime, setRedirectTime] = useState(5);


  const _init = () => {
    const deviceId = localStorage.getItem("x-device-service-id");
    if (params.type === "create-account") {
      verifyEmailLink({
        token: params.token,
        deviceId: deviceId
      })
        .then(function (response) {
          setStatueObj(response?.data);
          setVerifying(false);
        })
        .catch(function (error) {
          setStatueObj(error?.response?.data);
          setVerifying(false);
        });
    } else if (params.type === "forgot-password") {
      verifyEmailForgotPassword({
        token: params.token,
        deviceId: deviceId
      })
        .then(function (response) {
          setStatueObj(response?.data);
          setVerifying(false);
          let time = redirectTime;
          const downloadTimer = setInterval(function () {
            time--;
            console.log(time);
            setRedirectTime(time);
            if (time <= 0) {
              clearInterval(downloadTimer);
              navigate(`/reset-password/${response?.data?.data?.accessToken}`);
            }
          }, 1000);
        })
        .catch(function (error) {
          setStatueObj(error?.response?.data);
          setVerifying(false);
        });
    }
  };

  useEffect(() => {
    _init();
  }, [])


  const verificationStatus = () => {
    if (isVerifying) {
      return <div className="twm-error-content">
        <h2 className="site-text-primary">Verifying link...</h2>
        <p>To complete your Extendsware account setup, Please wait to verify your email address. This step ensures your account is secure and fully activated. We appreciate your cooperation and look forward to providing you with an excellent experience. Thank you for choosing Extendsware!</p>
      </div>
    }
    if (statusObj?.data?.type === "create_account_link_already_verified") {
      return <div className="twm-error-content">
        <h2 className="site-text-primary">You are already verified!</h2>
        <p>{statusObj?.data?.msg}</p>
        <a href="/home" onClick={_init} className="site-button">Go to Home</a>
      </div>
    } else if (statusObj?.data?.type === "invalid_link_forgot_password") {
      return <div className="twm-error-content">
        <h2 className="site-text-primary color-error">Verification Failed! invalid link</h2>
        <p>{statusObj?.data?.msg}</p>
        <a href="/forgot-password" className="site-button">Forgot Password</a>
      </div>
    } else if (statusObj?.data?.type === "email_verification_success") {
      return <div className="twm-error-content">
        <h2 className="site-text-primary color-success">Verification Successful!</h2>
        <p>Your email has been successfully verified, and your Extendsware account is now fully activated! You can now access all features and services. Thank you for completing the verification process. We're excited to have you on board and look forward to serving you. Enjoy your Extendsware experience!</p>
        <a href="/home" className="site-button">Go to Home</a>
      </div>
    } else if (statusObj?.data?.type === "email_verification_success_forgot_password") {
      return <div className="twm-error-content">
        <h2 className="site-text-primary color-success">Verification Successful!</h2>
        <p>Your email has been successfully verified, Redirecting to new password...</p>
        <p className="site-button">Redirecting... {redirectTime}</p>
      </div>
    } else {
      return <div className="twm-error-content">
        <h2 className="site-text-primary color-error">Verification Failed!</h2>
        <p>{statusObj?.data?.msg}</p>
        <a href="/login" className="site-button">Login Or Create Account</a>
      </div>
    }
  }

  return (
    <div className="section-full site-bg-gray twm-u-maintenance-area" style={{ background: '/assets/pngs/bg-1.png' }}>
      <div className="col-lg-5 col-md-12 center-item">

        {verificationStatus()}

      </div>
    </div>
  )
}

export default VerifyEmail;
