import api from "./index";

const login = (type, body) => {
    return api.post(`/login/${type}`, body);
}
const createAccount = (body) => {
    return api.post(`/user`, body);
}

const verifyEmailLink = (body) => {
    return api.post(`/verify-email-signup`, body);
}

const forgotPassword = (email) => {
    return api.get(`/forgot-password/${email}`);
}

const verifyEmailForgotPassword = (body) => {
    return api.post(`/verify-email-forgot-password`, body);
}

const resetPassword = (body) => {
    return api.put(`/reset-password`, body);
}

const me = () => {
    return api.get(`/me`);
}

const userList = () => {
    return api.get(`/users`);
}

const userDetails = (userId) => {
    return api.get(`/user-details/${userId}`);
}

const logoutDevice = () => {
    return api.get(`/logout-device`);
}

export {
    me,
    login,
    createAccount,
    verifyEmailLink,
    forgotPassword,
    verifyEmailForgotPassword,
    resetPassword,
    logoutDevice,
    userList,
    userDetails
}