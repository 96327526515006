import logo from "./logo.svg";
import "./App.css";
import "./index.css";
import Login from "./pages/Login";
import Home from "./pages/Home";
import CreateAccount from "./pages/CreateAccount";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import UAParser from 'ua-parser-js';
import Error404 from "./pages/Error404";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyEmail from "./pages/VerifyEmail";
import ResetPassword from "./pages/reserPassword";
import Chat from "./pages/chat";


function App() {


  const initProcess = () => {
    const deviceId = localStorage.getItem("x-device-service-id");
    if (!deviceId) {
      const ua = UAParser();
      localStorage.setItem("x-device-service-id",
        `${ua.device.model}/${ua.device.vendor}/${ua.browser.name}/${Date.now()}`);
    }
  }

  initProcess();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/home" element={<Home />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-email/:type/:token" element={<VerifyEmail />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </>

  )
}

export default App;
