import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ToastSuccess, ToastError } from "../components/toast-notification";
import { me, logoutDevice } from '../api/apiService'

function Home() {

  const navigate = useNavigate();
  const [profileObj, setProfileObj] = useState({});

  const _init = () => {
    const location = useLocation()
    const localToken = localStorage.getItem("x-device-access-token");
    const queryParameters = new URLSearchParams(location.search)
    const queryToken = queryParameters.get("accessToken");
    if (!localToken) {
      if (!queryToken) {
        window.location.href = "/login"
      } else {
        localStorage.setItem("x-device-access-token", queryToken);
      }
    }
    if (queryToken) {
      localStorage.setItem("x-device-access-token", queryToken);
      window.location.href = "/home"
    }


  };

  const fetchProfileData = () => {
    me()
      .then(function (response) {
        setProfileObj(response?.data?.data);
      })
      .catch(function (error) {
        ToastError(error?.response?.data?.message);
        navigate("/login", { replace: true })
      });
  }
  useEffect(() => {
    fetchProfileData();
  }, []);

  _init();

  const logout = () => {
    logoutDevice()
      .then(function (response) {
        localStorage.removeItem("x-device-access-token");
        ToastSuccess(response?.data.message);
        navigate("/login", { replace: true });
      })
      .catch(function (error) {
        ToastError(error?.response?.data?.message);
      });
  }

  return (
    <div class="container">
      <br></br>
      <br></br>
      <div class="row">

        <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
          <div class="theiaStickySidebar"><div class="side-bar-st-1">
            <div class="twm-candidate-profile-pic">
              <img src={profileObj.profileImage} alt="" />
              <div class="upload-btn-wrapper">

                <div id="upload-image-grid"></div>
                <button class="site-button button-sm">Upload Photo</button>
                <input type="file" name="myfile" id="file-uploader" accept=".jpg, .jpeg, .png" />
              </div>

            </div>

            <div class="twm-mid-content text-center">
              <a href="candidate-detail.html" class="twm-job-title">
                <h4>{profileObj.fullName}</h4>
              </a>
              <p>{profileObj.email}</p>
            </div>

            <div class="twm-nav-list-1">
              <ul>
                <li class="active"><a href="#"><i class="fa fa-user"></i>Profile</a></li>
                <li><a href="#"><i class="fa fa-fingerprint"></i>Change Password</a></li>
                <li><a onClick={logout} href="#"><i class="fa fa-share-square"></i> Logout</a></li>
              </ul>
            </div>

          </div></div></div>

        <div class="col-xl-9 col-lg-8 col-md-12 m-b30">
          <div class="twm-right-section-panel site-bg-gray">
            <form>


              <div class="panel panel-default">
                <div class="panel-heading wt-panel-heading p-a20">
                  <h4 class="panel-tittle m-a0">Profile Details</h4>
                </div>
                <div class="panel-body wt-panel-body p-a20 m-b30 ">

                  <a href="/chat" class="site-button">Chat</a>


                </div>
              </div>

            </form>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Home;
