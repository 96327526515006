import { useLocation } from 'react-router-dom';

function Error404() {


  return (
    <div class="container">
      <div class="twm-error-wrap">
        <div class="row">

          <div class="col-lg-6 col-md-12">
            <div class="twm-error-image">
              <img src="/assets/pngs/error-404.png" alt="#" />
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="twm-error-content">
              <h2 class="twm-error-title">404</h2>
              <h4 class="twm-error-title2 site-text-primary">We Are Sorry, Page Not Found</h4>
              <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
              <a href="/" class="site-button">Go To Home</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Error404;
