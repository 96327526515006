

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { login } from '../api/apiService'
import { ToastSuccess, ToastError } from "../components/toast-notification";

function Login() {

  const navigate = useNavigate();
  const xDeviceId = localStorage.getItem("x-device-service-id");

  const [formValues, setFormValues] = useState({ email: '', password: '', deviceId: '' });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!formValues.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formValues.password) {
      errors.password = 'Password is required';
    } else if (formValues.password.length < 5) {
      errors.password = 'Password must be at least 6 characters';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const tryLogin = (bodyData) => {
    bodyData.deviceId = xDeviceId;
    login("email", bodyData)
      .then(function (response) {
        console.log(response?.data?.data.accessToken);
        localStorage.setItem("x-device-access-token", response?.data?.data.accessToken);
        ToastSuccess(response?.data?.message);
        window.location.href = "/home";
      })
      .catch(function (error) {
        ToastError(error?.response?.data?.message);

      });
  }

  const googleLogin = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/social-login/google`;
    const deviceId = xDeviceId;
    const fromUrl = process.env.REACT_APP_HOME_URL;
    const appName = process.env.REACT_APP_NAME;
    window.location.href = `${url}?appName=${appName}&fromUrl=${fromUrl}&deviceId=${deviceId}`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    validate(formValues)

    if (validate(formValues)) {
      tryLogin(formValues)
    }
  };

  return (
    <div className="page-content">

      <div className="twm-home4-banner-section site-bg-light-purple">
        <div className="row">
          <div className="col-xl-5 col-lg-12 col-md-12">
            <div className="twm-log-reg-form-wrap">
              <div className="twm-log-reg-logo-head">
                <div className="twm-log-reg-head">
                  <span className="log-reg-form-title">Extendsware</span>
                </div>
              </div>

              <div className="twm-log-reg-inner">
                <div className="twm-tabs-style-2">

                  <div className="col-md-12">
                    <div className="form-group">
                      <button type="submit" onClick={googleLogin} className="log_with_google">
                        <img src="/assets/icons/google-icon.png" alt="" />
                        Continue with Google
                      </button>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="tab-content" id="myTab2Content">
                      <div className="tab-pane fade show active" id="twm-login-candidate">
                        <div className="row">

                          {formErrors.email && <p className="color-error">{formErrors.email}</p>}
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <input name="email" value={formValues.email}
                                onChange={handleChange} type="text" required="" className="form-control" placeholder="Email*" />
                            </div>
                          </div>
                          {formErrors.password && <p className="color-error">{formErrors.password}</p>}
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <input name="password" value={formValues.password}
                                onChange={handleChange} type="password" className="form-control" required="" placeholder="Password*" />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="twm-forgot-wrap">
                              <div className="form-group mb-3">
                                <div className="form-check">
                                  <input type="checkbox" className="form-check-input" id="Password4" />
                                  <label className="form-check-label rem-forgot" for="Password4">Remember me <a href="/forgot-password" className="site-text-primary">Forgot Password</a></label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <button type="submit" onClick={handleSubmit} className="site-button">Log in</button>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="twm-forgot-wrap">
                              <div className="form-group mb-3">
                                <div className="form-check">
                                  <label className="form-check-label rem-forgot" for="Password4">Don't have an account <a href="/create-account" className="site-text-primary">Create Account</a></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="twm-bnr-right-section anm" data-wow-delay="1000ms" data-speed-x="2" data-speed-y="2">

              <div className="twm-graphics-h3 twm-bg-line">
                <img src="/assets/pngs/bg-line.png" alt="" />
              </div>

              <div className="twm-graphics-user twm-user">
                <img src="/assets/pngs/user.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-bg-plate">
                <img src="/assets/pngs/bg-plate.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-checked-plate">
                <img src="/assets/pngs/checked-plate.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-blue-block">
                <img src="/assets/pngs/blue-block.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-color-dotts">
                <img src="/assets/pngs/color-dotts.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-card-large anm" data-speed-y="-2" data-speed-scale="-15"
                data-speed-opacity="50">
                <img src="/assets/pngs/card.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-card-s1 anm" data-speed-y="2" data-speed-scale="15" data-speed-opacity="50">
                <img src="/assets/pngs/card-s1.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-card-s2 anm" data-speed-x="-4" data-speed-scale="-25"
                data-speed-opacity="50">
                <img src="/assets/pngs/card-s2.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-white-dotts">
                <img src="/assets/pngs/white-dotts.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-top-shadow anm" data-speed-x="-16" data-speed-y="2" data-speed-scale="50"
                data-speed-rotate="25">
                <img src="/assets/pngs/top-shadow.png" alt="" />
              </div>

              <div className="twm-graphics-h3 twm-bottom-shadow anm" data-speed-x="16" data-speed-y="2" data-speed-scale="20"
                data-speed-rotate="25">
                <img src="/assets/pngs/bottom-shadow.png" alt="" />
              </div>


            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Login;
