import axios from 'axios';

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        // 'x-user-type': 'admin',
        'Authorization': localStorage.getItem('x-device-access-token')
    }
});

export const notifier = axios.create({
    baseURL: process.env.REACT_APP_NOTIFIER_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        // 'x-user-type' : 'admin',
        'Authorization': localStorage.getItem('x-device-access-token')
    }
});
