
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { forgotPassword } from '../api/apiService'
import { ToastSuccess, ToastError } from "../components/toast-notification";

function ForgotPassword() {
  const xDeviceId = localStorage.getItem("x-device-service-id");
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ email: '' });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!formValues.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = 'Email address is invalid';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const tryForgot = (data) => {
    forgotPassword(data.email)
      .then(function (response) {
        ToastSuccess(response?.data?.message);
        navigate(`/login`);
      })
      .catch(function (error) {
        ToastError(error?.response?.data?.message);

      });
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    validate(formValues)

    if (validate(formValues)) {
      tryForgot(formValues)
    }
  };

  const googleLogin = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/social-login/google`;
    const deviceId = xDeviceId;
    const fromUrl = process.env.REACT_APP_HOME_URL;
    const appName = process.env.REACT_APP_NAME;
    window.location.href = `${url}?appName=${appName}&fromUrl=${fromUrl}&deviceId=${deviceId}`;
  }

  return (
    <div class="page-content">

      <div class="twm-home4-banner-section site-bg-light-purple">
        <div class="row">
          <div class="col-xl-5 col-lg-12 col-md-12">
            <div class="twm-log-reg-form-wrap">
              <div class="twm-log-reg-logo-head">
                <div class="twm-log-reg-head">
                  <span class="log-reg-form-title">Extendsware</span>
                </div>
              </div>

              <div class="twm-log-reg-inner">
                <div class="twm-tabs-style-2">

                  <div class="col-md-12">
                    <div class="form-group">
                      <button onClick={googleLogin} type="submit" class="log_with_google">
                        <img src="/assets/icons/google-icon.png" alt="" />
                        Continue with Google
                      </button>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div class="tab-content" id="myTab2Content">
                      <div class="tab-pane fade show active" id="twm-login-candidate">
                        <div class="row">

                          <div class="col-lg-12">
                            <div class="form-group mb-3">
                              {formErrors.email && <p className="color-error">{formErrors.email}</p>}
                              <input onChange={handleChange} name="email" type="text" class="form-control" required="" placeholder="Email*" />
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="twm-forgot-wrap">
                              <div class="form-group mb-3">
                                <div class="form-check">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <button type="submit" class="site-button">Submit</button>
                            </div>

                          </div>
                          <div class="col-lg-12">
                            <div class="twm-forgot-wrap">
                              <div class="form-group">
                                <div class="form-check">
                                  <label class="form-check-label rem-forgot" for="Password4">Already have an account <a href="/login" class="site-text-primary">Login</a></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-12 col-md-12">
            <img src="/assets/pngs/login-bg.png" alt="" />
          </div>
        </div>

      </div>

    </div>
  )
}

export default ForgotPassword;
